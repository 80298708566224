interface ICTAButton {
    onClick: () => void
    text: string
    subText: string,
    icon: string
}
const CTAButtonComponent = (props: ICTAButton) => {
    const {onClick, icon, text, subText} = props
    return <button onClick={onClick} className="p-l p-r bg-primary cta-button-component radius-default no-border cursor-pointer">
        <span className="flex-row flex-middle flex-no-wrap">
            <span className="flex-column icon-area bg-white flex-center flex-middle">
                <img src={icon} alt={`${text} ${subText}`} />
            </span>
            <span className="flex-column text-white flex-left size-small p-l-s no-wrap">
                <strong className="text-uppercase">{text}</strong>
                <span>{subText}</span>
            </span>
        </span>
    </button>
}

export default CTAButtonComponent