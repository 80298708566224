import * as React from 'react'
import {IMAGES} from "../../constants/IMAGES";
import CTAButtonComponent from "../global/CTAButtonComponent";
import useResponsive from "../../hooks/useResponsive";
import {MODALS} from "./ModalContainer";
import {ModalContext} from "../../hooks/modalContext";
import {CATEGORY_LIST} from "../../data/vehicles";
import {FilterContext} from "../../hooks/filtersContext";
import {AsideContext} from "../../hooks/asideContext";
import {CONFIG} from "../../constants/config";

const HeaderComponent = () => {
    const [activeResponsiveNav, handleActiveResponsiveNav] = React.useState(false)
    const {handleModalData} = React.useContext(ModalContext);
    const {handleFilterData, filterData} = React.useContext(FilterContext);
    const {handleAsideData} = React.useContext(AsideContext);
    const {isMobile} = useResponsive()
    const whBrand = isMobile ? {w: 120,h: 48} : {w: 160, h: 64}
    const closeNav = () => {
        handleActiveResponsiveNav(false)
    }
    const filterByCategory = () => {
        handleFilterData(CATEGORY_LIST.all)
        setTimeout(() => {
            document.getElementById(CATEGORY_LIST.all.id)?.scrollIntoView({block: "start", behavior: "smooth"})
        }, 400)
    }
    const setForDates = () => {
        handleModalData({
            showModal: true,
            extraData: {},
            modal: MODALS.ONE_CLICK_MODAL,
            title: 'RENTA TU AUTO',
            subtitle: 'EN UN CLICK'
        })
    }
    const callAbout = () => {
        handleAsideData({
            aside: 'ABOUT_US',
            title: 'Quienes',
            subtitle: 'Somos',
            showAside: true,
            bottomTitle: 'AUTOFAST'
        })
    }
    const workAbout = () => {
        handleAsideData({
            aside: 'WORK',
            title: 'Trabaja con',
            subtitle: 'Nosotros',
            showAside: true,
            bottomTitle: 'Requerimos vehículos'
        })
    }
    const setFormContact = () => {
        handleModalData({
            showModal: true,
            extraData: {},
            modal: MODALS.CONTACT_MODAL,
            title: 'CONTACTAR A',
            subtitle: 'AUTOFAST'
        })
    }
    const setRequirements = () => {
        handleModalData({
            showModal: true,
            extraData: {},
            modal: MODALS.REQUIREMENTS_MODAL,
            title: 'REQUISITOS',
            subtitle: 'AUTOFAST'
        })
    }
    return  <header className="main-header flex-row flex-middle">
        <div className="brand flex-column">
            <img width={whBrand.w} height={whBrand.h} src={IMAGES.BRAND_PNG} alt="AUTOFAST Alquiler de autos en Quito - Ecuador"/>
        </div>
        <div className="cta-button flex-right flex-column p-r-s">
            <CTAButtonComponent icon={IMAGES.HAND_CLICK} onClick={setForDates} text="Tu auto" subText="En un Click" />
        </div>
        <div>
            <button className="responsive-button" onClick={() => handleActiveResponsiveNav(true)} />
        </div>
        <nav className={`${activeResponsiveNav && 'active'}`}>
            <span className="close-button width-100 flex-row flex-end">
                <img
                    width={24}
                    height={24}
                    src={IMAGES.CLOSE}
                    alt="Cerrar navegación"
                    className="icon-24x"
                    onClick={() => handleActiveResponsiveNav(false)}
                />
            </span>
            <h3 className="width-100 border-b border-gray border-lighten-4 p-b-s">
                AUTOFAST <span className="text-primary">Rent a Car</span>
            </h3>
            <ul onClick={closeNav}>
                <li><a onClick={filterByCategory}>Vehículos</a></li>
                <li><a onClick={callAbout}>Nosotros</a></li>
                <li><a onClick={setRequirements}>Requisitos</a></li>
                <li><a onClick={setFormContact}>Contactar</a></li>
                <li><a onClick={workAbout}>Trabaja con nosotros</a></li>
            </ul>
            <div className="social-list">
                <a href="https://www.facebook.com/alquilerdeautosenquito/" target="_blank"><img width={24} height={24} src={IMAGES.FACEBOOK} className="icon-24x" alt="Facebook"/></a>
                <a href="https://instagram.com/autofast_alquilerdevehiculos?igshid=YmMyMTA2M2Y=" target="_blank"><img width={24} height={24} src={IMAGES.INSTAGRAM} className="icon-24x" alt="Instagram"/></a>
                <a href={`https://api.whatsapp.com/send?phone=${CONFIG.phone}&text=Hola%20...%20Me%20interesa%20alquilar%20un%20vehículo`} target="_blank"><img width={24} height={24} src={IMAGES.WHATSAPP} className="icon-24x" alt="Whatsapp"/></a>
            </div>
        </nav>
    </header>
}

export default HeaderComponent