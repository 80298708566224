interface IIconButton {
    onPress: () => void
    label: string
    icon: string
    inactive?: boolean
    extraClass?: string
    disable?: boolean
}
const IconButtonComponent = (props: IIconButton) => {
    const {onPress, disable, icon, label, inactive, extraClass} = props
    return <button
        disabled={disable}
        onClick={onPress}
        className={`${extraClass || ''} ${disable && 'disable'} p-l-s p-r-s icon-button-component radius-default cursor-pointer border ${inactive ? 'border-gray border-lighten-2' : 'border-primary'}`}
    >
        <span className="flex-column flex-middle flex-no-wrap label">
            <span className="flex-row icon-area flex-center flex-middle align-center">
                <img src={icon} alt={label} />
            </span>
            <span className="flex-column label-container flex-left no-wrap">
                <strong className="text-white">{label}</strong>
            </span>
        </span>
    </button>
}

export default IconButtonComponent