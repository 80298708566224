import * as React from "react";
import {IMAGES} from "../../constants/IMAGES";

const RequirementsModal = () => {
    return <div className="width-100 contact-modal form-modal">
        <div className="left-area p-t-s">
            <div>
                <img src={IMAGES.EMAIL_LINE} alt="contacto" className="icon-64x"/>
            </div>
        </div>
        <div className="contact-form">
            <div className="form-area width-100 p-b">
                <h3 className="text-primary">Documentación requerida:</h3>
                <ul>
                    <li>Identificación: Nacional o Internacional.</li>
                    <li>Licencia de conducir: Nacional o internacional.</li>
                    <li>Tarjeta de Crédito para la garantía.</li>
                </ul>
                <p>Todas las transacciones para el alquiler de nuestros vehículos son 100% seguras y ágiles, si tienes alguna inquietud no dudes en contactarnos</p>
            </div>
        </div>
    </div>
}

export default RequirementsModal