import * as React from 'react'
import useResponsive from "../hooks/useResponsive";
import {IMAGES} from "../constants/IMAGES";
import {CATEGORIES, CATEGORY_LIST, VEHICLES} from "../data/vehicles";
import VehicleCardComponent from "../components/global/VehicleCardComponent";
import {FilterContext} from "../hooks/filtersContext";

const HomePage = () => {
    const {isMobile, isBig} = useResponsive()
    const {handleFilterData, filterData} = React.useContext(FilterContext);
    const bgImage = isMobile ? IMAGES.BG_RESPONSIVE : isBig ? IMAGES.BG_WEB : IMAGES.BG
    const filterByCategory = () => {
        handleFilterData(CATEGORY_LIST.all)
        setTimeout(() => {
            document.getElementById(CATEGORY_LIST.all.id)?.scrollIntoView({block: "start", behavior: "smooth"})
        }, 400)
    }
    const getFilterContent = () => {
        if (filterData.id === 'all') {
            return CATEGORIES
        }
        return CATEGORIES.filter(item => item.id === filterData.id)
    }
    const webContent = <div className="info-container">
        <h1 className="main-title super-h1">
            <span className="title-a">Alquiler</span>
            <span className="title-b text-primary">De Autos</span>
        </h1>
        <div className="main-description">
            <p className="description-a">de autos en Quito, contamos con una amplia flota para satisfacer las necesidades de nuestros clientes a precios competitivos</p>
            <p className="description-b">modernos, seguros, en constante mantenimiento para preservar la seguridad de nuestros clientes</p>
        </div>
        <div className="main-description">
            <p className="description-a" />
            <p className="description-b link-content">
                <a onClick={filterByCategory} className="strong width-100 text-primary p-l border-l-w-2 border-gray border-l">
                    Conoce nuestra flota
                </a>
            </p>
        </div>
    </div>
    const responsiveContent = <div className="info-container">
        <h1 className="main-title super-h1">
            <span className="title-a">Alquiler</span>
        </h1>
        <div className="main-description">
            <p className="description-a">de autos en Quito, contamos con una amplia flota para satisfacer las necesidades de nuestros clientes a precios competitivos</p>
        </div>
        <h2 className="main-title super-h1">
            <span className="title-b text-primary">De Autos</span>
        </h2>
        <div className="main-description">
            <p className="description-b">modernos, seguros, en constante mantenimiento para preservar la seguridad de nuestros clientes</p>
        </div>
        <div className="main-description">
            <p className="description-a" />
            <p className="description-b link-content">
                <a onClick={filterByCategory} className="strong width-100 text-primary p-l border-l-w-2 border-gray border-l">
                    Conoce nuestra flota
                </a>
            </p>
        </div>
    </div>
    return  <div className="home-page width-100 p-b-lg">
        <div className="home-banner" style={{backgroundImage: `url("${bgImage}")`}}>
            {isMobile ? responsiveContent: webContent }
        </div>
        
        <div className="container container-flex container-static border-t border-gray border-lighten-4">
            <div className="row flex-middle title-block">
                <div className="p-t-lg p-b-lg p-l p-r">
                    <h2 className="size-h1 flex-column">
                        <span className="line-height-small">Nuestra <strong className="text-primary">flota de</strong></span>
                        <span className="regular primary-font size-2 line-height-small">Vehículos en alquiler</span>
                    </h2>
                </div>
                <div className="col-l8 p-l p-r">
                    <p>
                        En Autofast rent a car, contamos con una amplia flota para satisfacer las necesidades de nuestros clientes.
                    </p>
                </div>
            </div>
            {getFilterContent().map((category, key) => {
                return category.id !== 'all' && <div className="width-100" key={key}>
                    <div className="row p-b-lg p-t-lg">
                        <div id="all" className="id-reference" />
                        <div id={category.id} className="id-reference" />
                        <div className="col-s12 p-b">
                            <h3 className="flex-row flex-middle size-h4 primary-font text-gray text-lighten-2 regular text-uppercase flex-no-wrap line-height-small">
                                <span className="flex-column radius-50 bg-gray bg-lighten-4 flex-middle flex-center p-s-all">
                                    <img width={24} height={24} src={category.iconP} alt={`Alquiler de autos ${category}`}/>
                                </span>
                                <span className="p-l-s">{category.fullName} en alquiler</span>
                            </h3>
                        </div>
                    </div>
                    <div className="row">
                        {VEHICLES.filter(vehicle => vehicle.categories.includes(category.id)).map((vehicle, key) => {
                          return <div className="col-l4 col-m6 col-s12" key={key}>
                              <VehicleCardComponent {...vehicle} />
                          </div>
                        })}
                    </div>
                </div>
            })}
        </div>
    </div>
}

export default HomePage