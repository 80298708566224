import * as React from "react";

const useResponsive = () => {
    const [isResponsive, handleIsResponsive] = React.useState({isMobile: false, isBig: false})
    const handleResizing = () => {
        if (window.innerWidth <= 769) {
            handleIsResponsive(
                {
                    isMobile: true,
                    isBig: false
                }
            )
        } else {
            handleIsResponsive({
                isMobile: false,
                isBig: window.innerWidth >= 1300
            })
        }
    };
    React.useEffect(() => {
        handleResizing()
        window.addEventListener('resize', handleResizing);
        return () => {
            window.removeEventListener('resize', handleResizing);
        };
    }, []);
    return isResponsive
}

export default useResponsive