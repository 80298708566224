import * as React from "react";
import {ModalContext} from "../../hooks/modalContext";
import {MODALS} from "../layout/ModalContainer";
import {IMAGES} from "../../constants/IMAGES";
import {InputComponent} from "../global/InputComponent";
import {SelectComponent} from "../global/SelectComponent";
import {VEHICLES} from "../../data/vehicles";
import RadioButtonComponent from "../global/RadioButtonComponent";
import {ButtonComponent} from "../global/ButtonComponent";
import DatePickerComponent from "../global/DatePickerComponent";

const ScheduleModal = () => {
    const [formData, handleFormData] = React.useState({
        vehicle: '',
        user: '',
        phone: '',
        email: '',
        type: 'WHATSAPP'
    })
    const [formDateFrom, handleFormDatesFrom] = React.useState('')
    const [formDateTo, handleFormDatesTo] = React.useState('')
    const {handleModalData, modalData} = React.useContext(ModalContext);
    const changeData = (value: string | number, id: string) => {
        handleFormData({
            ...formData,
            [id]: value
        })
    }
    const changeDateFrom = (date: string) => {
        handleFormDatesFrom(date)
        handleFormDatesTo('')
    }
    const changeDateTo = (date: string) => {
        handleFormDatesTo(date)
    }
    const vehicleData = VEHICLES.find(vehicle => vehicle.model === formData.vehicle)
    const getModalData = () => {
        switch (modalData.modal) {
            case MODALS.DATE_MODAL:
                return {
                    showWhatsapp: true,
                    showDates: false,
                    showVehicle: true,
                    buttonText: 'Alquilar vehículo',
                    description: 'Por favor completa el siguiente formulario para generar una pre reserva en las fechas seleccionadas, uno de nuestros asesores te contactará',
                    leftContent: <span className="flex-row bg-gray bg-lighten-4 radius-50 p-all">
                        <img src={IMAGES.CALENDAR} width={18} height={18} alt=""/>
                    </span>
                }
            case MODALS.WHATSAPP_MODAL:
                return {
                    showWhatsapp: false,
                    showDates: true,
                    showVehicle: true,
                    buttonText: 'Alquilar vehículo por whatsapp',
                    description: 'Por favor completa el siguiente formulario para generar una pre reserva por whatsapp',
                    leftContent: <span className="flex-row bg-gray bg-lighten-4 radius-50 p-all">
                        <img src={IMAGES.WHATSAPP_LINE} width={18} height={18} alt=""/>
                    </span>
                }
            case MODALS.ONE_CLICK_MODAL:
                return {
                    showWhatsapp: true,
                    showDates: true,
                    showVehicle: true,
                    buttonText: 'Alquilar vehículo',
                    description: 'Por favor completa el siguiente formulario para realizar una pre reserva de manera ágil por el medio de tu preferencia',
                    leftContent: <span className="flex-row bg-gray bg-lighten-4 radius-50 p-all">
                        <img src={IMAGES.HAND_CLICK} width={18} height={18} alt=""/>
                    </span>
                }
            case MODALS.VEHICLE_MODAL:
                return {
                    showWhatsapp: true,
                    showDates: true,
                    showVehicle: false,
                    buttonText: 'Alquilar vehículo',
                    description: `Por favor completa el siguiente formulario para realizar la pre reserva de tu ${vehicleData?.brand} ${vehicleData?.model} y recibir la atención inmediata de uno de nuestros asesores`,
                    leftContent: <span className="flex-column p-r line-height-small border-r border-gray border-lighten-3">
                        <span className="size-small">DESDE</span>
                        <span className="size-3 strong text-primary primary-font line-height-small">$ {vehicleData?.price}</span>
                        <span className="size-small">El día</span>
                    </span>
                }
            case MODALS.PROMO_MODAL:
                return {
                    showWhatsapp: true,
                    showDates: true,
                    showVehicle: true,
                    buttonText: 'Acceder a la promoción',
                    descriptionTitle: 'Renta un auto por un mes y obtén 5 días ¡GRATIS!',
                    description: 'Por favor completa el siguiente formulario para generar una pre reserva y acceder a la promoción, uno de nuestros asesores te contactará',
                    leftContent: <span className="flex-row bg-gray bg-lighten-4 radius-50 p-all">
                        <img src={IMAGES.CALENDAR} width={18} height={18} alt=""/>
                    </span>
                }
        }
    }
    const modalContent = getModalData()
    React.useEffect(() => {
        console.log('........')
        if (modalData.modal === MODALS.DATE_MODAL) {
            handleFormDatesFrom(modalData.extraData.from)
            handleFormDatesFrom(modalData.extraData.to)
        }
        if (modalData.modal === MODALS.VEHICLE_MODAL) {
            handleFormData({
                ...formData,
                vehicle: modalData.extraData.model
            })
        }
    }, [])
    console.log('formData', formData)
    if (!modalContent) {
        return null
    }
    return <div className="width-100 schedule-modal">
        <div className="flex-row flex-middle">
            <div className="flex-column">
                {modalContent.leftContent}
            </div>
            <div className="flex-column flex-1 line-height-small p-l">
                {modalContent.descriptionTitle &&
                    <div className="strong">{modalContent.descriptionTitle}</div>
                }
                <div>
                    {modalContent.description}
                </div>
            </div>
        </div>
        <div className="schedule-form">
            <div className="form-area width-100 p-t">
                {modalContent.showVehicle &&
                    <div className="width-100 p-b">
                        <SelectComponent
                            options={VEHICLES.map(vehicle => {
                                return {
                                    id: vehicle.model,
                                    label: <span className="flex-row flex-middle">
                                        <span className="flex-1">{`${vehicle.brand} - ${vehicle.model}`}</span>
                                        <span><img src={vehicle.image_s} width={48} alt={vehicle.model}/></span>
                                    </span>
                                }
                            })}
                            valueId="vehicle"
                            onChangeValue={changeData}
                            value={formData.vehicle}
                            placeholder="Ejm: Chevrolet Aveo"
                            label="Selecciona un vehículo"
                        />
                    </div>
                }
                {modalContent.showDates &&
                    <div className="width-100 p-b flex-row">
                        <div className="flex-column flex-1 p-r">
                            <DatePickerComponent
                                key={"m1"}
                                type="WHITE"
                                label="Desde"
                                icon={IMAGES.DATE_TO_GRAY}
                                id="from"
                                dateValue={formDateFrom}
                                onChange={((value, id) => changeDateFrom(value))}
                            />
                        </div>
                        <div className="flex-column flex-1">
                            <DatePickerComponent
                                key={"m2"}
                                type="WHITE"
                                label="Hasta"
                                icon={IMAGES.DATE_TO_GRAY}
                                id="to"
                                dateValue={formDateTo}
                                onChange={((value, id) => changeDateTo(value))}
                                disable={!formDateFrom}
                                minDate={formDateFrom}
                            />
                        </div>
                    </div>
                }
                <div className="width-100 p-b">
                    <InputComponent
                        label="Ingresa tus nombres"
                        placeholder="Ejm: Adriana Suarez"
                        valueId="user"
                        value={formData.user}
                        onChangeValue={changeData}
                        icon={<img src={IMAGES.NAME_SELECT} alt="Nombres" width={24} height={24}/>}
                    />
                </div>
                {modalContent.showWhatsapp &&
                    <div className="width-100 p-b">
                        <RadioButtonComponent
                            options={[
                                {
                                    id: 'WHATSAPP',
                                    label: <span className="flex-row flex-middle">
                                        <span className="flex-column">
                                            <img src={IMAGES.WHATSAPP_LINE} width={24} height={24} alt="Whatsapp"/>
                                        </span>
                                        <span className="flex-column p-l-s size-default primary-font">Reservar por Whatsapp</span>
                                    </span>
                                },
                                {
                                    id: 'EMAIL',
                                    label: <span className="flex-row flex-middle">
                                        <span className="flex-column">
                                            <img src={IMAGES.EMAIL_LINE} width={24} height={24} alt="Whatsapp"/>
                                        </span>
                                        <span className="flex-column p-l-s size-default primary-font">Reservar por Email</span>
                                    </span>
                                }
                            ]}
                            value={formData.type}
                            onChangeValue={changeData}
                            valueId="type"
                        />
                    </div>
                }
                {formData.type === 'EMAIL' &&
                    <>
                        <div className="width-100 p-b">
                            <InputComponent
                                label="Ingresa tu email"
                                placeholder="Ejm: aduarez@email.com"
                                valueId="email"
                                value={formData.email}
                                onChangeValue={changeData}
                                icon={<img src={IMAGES.MAIL_FORM} alt="Email" width={24} height={24}/>}
                            />
                        </div>
                        <div className="width-100 p-b">
                            <InputComponent
                                label="Ingresa tu teléfono"
                                placeholder="Ejm: 0999112222"
                                valueId="phone"
                                value={formData.phone}
                                onChangeValue={changeData}
                                icon={<img src={IMAGES.CALL} alt="Teléfono" width={24} height={24}/>}
                            />
                        </div>
                    </>
                }
                <div className="width-100 align-right">
                    <ButtonComponent buttonText={modalContent.buttonText} buttonType="primary" />
                </div>
            </div>
            <div className="image-area">
                <img src={vehicleData?.image || IMAGES.NO_VEHICLE} alt="vehiculo" className="vehicle-img"/>
                {vehicleData &&
                    <div className="flex-row p-t">
                        <div className="flex-column line-height-small">
                            <div className="flex-row flex-middle flex-no-wrap">
                                <span className="p-r-s"><img alt="Asientos" width={24} height={24} src={IMAGES.CHAIRS}/></span>
                                <span>{vehicleData.chairs} Asientos</span>
                            </div>
                            <div className="flex-row flex-middle">
                                <span className="p-r-s"><img alt="Puertas" width={24} height={24} src={IMAGES.DOOR}/></span>
                                <span>{vehicleData.doors} Puertas</span>
                            </div>
                            {vehicleData.ac &&
                                <div className="flex-row flex-middle flex-no-wrap">
                                    <span className="p-r-s"><img alt="Puertas" width={24} height={24} src={IMAGES.AC}/></span>
                                    <span>Aire Acondicionado</span>
                                </div>
                            }
                            {vehicleData.bluetooth &&
                                <div className="flex-row flex-middle flex-no-wrap">
                                    <span className="p-r-s"><img alt="Puertas" width={24} height={24} src={IMAGES.BLUETOOTH}/></span>
                                    <span>Bluetooth</span>
                                </div>
                            }
                            <div className="flex-row flex-middle flex-no-wrap">
                                <span className="p-r-s"><img alt="Puertas" width={24} height={24} src={IMAGES.TRACTION}/></span>
                                <span>Tracción {vehicleData.traction}</span>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </div>
    </div>
}

export default ScheduleModal