import * as React from "react";

export type IAsideComponents = 'ABOUT_US' | 'WORK'

export interface IAside {
    showAside: boolean, aside: IAsideComponents | '', title: string, subtitle: string, bottomTitle: string
}

export const AsideContext = React.createContext({
     asideData: {
         showAside: false,
         aside: '',
         title: '',
         subtitle: '',
         bottomTitle: '',
     } as IAside, handleAsideData: (data: IAside) => {}
});
