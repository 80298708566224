import * as React from "react";
import {IMAGES} from "../../constants/IMAGES";
import {ButtonComponent} from "../global/ButtonComponent";
import {MODALS} from "./ModalContainer";
import {ModalContext} from "../../hooks/modalContext";

interface IPromos {
    isOpen: boolean
    onClose: () => void
}

const PromosContainer = (props: IPromos) => {
    const {onClose, isOpen} = props
    const {handleModalData} = React.useContext(ModalContext);
    const validateScape = (e: KeyboardEvent) => {
        if (isOpen && e.key === 'Escape') {
            close()
        }
    }
    const close = () => {
        onClose()
    }
    const setFormPromo = () => {
        onClose()
        handleModalData({
            showModal: true,
            extraData: {},
            modal: MODALS.PROMO_MODAL,
            title: 'ACCEDE A LA PROMOCIÓN',
            subtitle: 'AUTO POR UN MES'
        })
    }

    React.useEffect(() => {
        window.addEventListener('keyup', validateScape)
        return () => {
            window.removeEventListener('keyup', validateScape)
        }
    }, [])

    if (!isOpen) {
        return null
    }
    return (
        <div
            className={`promos-view ${isOpen && 'promos-active'}`}
        >
            <div className="close-area" onClick={close} />
            <div
                className={`promo-container`}
            >
                <div className="text-primary strong size-5 p-b-s">
                    Auto por un mes
                </div>
                <div className="flex-row flex-no-wrap">
                    <div className="flex-column">
                        <div className="bg-gray bg-lighten-4 radius-50 p-all flex-row">
                            <img src={IMAGES.CALENDAR} alt=""/>
                        </div>
                    </div>
                    <div className="flex-column p-l">
                        Renta un auto por un mes y obtén 5 días <strong>¡GRATIS!</strong>
                    </div>
                </div>
                <div className="flex-row flex-end p-t-s">
                    <ButtonComponent buttonText="Adquirir promoción" buttonType="primary" onClick={setFormPromo} />
                </div>
            </div>
        </div>
    )
}

export default PromosContainer