import * as React from "react";

const useDateFromTo = () => {
    const [dateFrom, handleDateFrom] = React.useState('')
    const [dateTo, handleDateTo] = React.useState('')
    const changeDateFrom = (from: string) => {
        handleDateFrom(from)
    }
    const changeDateTo = (to: string) => {
        handleDateTo(to)
    }
    return [dateFrom, dateTo, changeDateFrom, changeDateTo] as const
}

export default useDateFromTo