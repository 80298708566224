import React from 'react';
import './scss/index.scss';
import MainLayout from "./components/layout/MainLayout";
import HomePage from "./pages/Home";

function App() {
  return (
    <MainLayout>
      <HomePage />
    </MainLayout>
  );
}

export default App;
