import * as React from "react";

export interface IModal {
    showModal: boolean, modal: string, title: string, subtitle: string, extraData: any
}

export const ModalContext = React.createContext({
     modalData: {
         showModal: false,
         modal: '',
         title: '',
         subtitle: '',
         extraData: {}
     } as IModal, handleModalData: (data: IModal) => {}
});
