import * as React from 'react'
import HeaderComponent from "./HeaderComponent";
import FooterComponent from "./FooterComponent";
import ModalContainer from "./ModalContainer";
import {IModal, ModalContext} from "../../hooks/modalContext";
import {FilterContext, IFilterContext} from "../../hooks/filtersContext";
import {CATEGORY_LIST} from "../../data/vehicles";
import {AsideLayoutComponent} from "./AsideLayoutComponent";
import {AsideContext, IAside} from "../../hooks/asideContext";

interface IMainLayout {
    children: JSX.Element
}

const MainLayout = (props: IMainLayout) => {
    const [modalData, handleModalData] = React.useState<IModal>({
        showModal: false, modal: '', title: '', subtitle: '', extraData: {}
    })
    const [filterData, handleFilterData] = React.useState<IFilterContext>(CATEGORY_LIST.all)
    const [asideData, handleAsideData] = React.useState<IAside>({aside: '', showAside: false, subtitle: '', title: '', bottomTitle: ''})
    const setModalData = (modalData: IModal): void => {
        if (modalData.showModal) {
            document.body.classList.add('open-modal')
        } else {
            document.body.classList.remove('open-modal')
        }
        handleModalData(modalData)
    }
    const  {children} = props
    return  <ModalContext.Provider value={{modalData, handleModalData: setModalData}}>
        <FilterContext.Provider value={{filterData, handleFilterData}}>
            <AsideContext.Provider value={{asideData, handleAsideData}}>
                <div className="main-layout width-100">
                    <HeaderComponent />
                    <div className="main-content">
                        {children}
                    </div>
                    <FooterComponent />
                    <ModalContainer />
                    <AsideLayoutComponent />
                </div>
            </AsideContext.Provider>
        </FilterContext.Provider>
    </ModalContext.Provider>
}

export default MainLayout