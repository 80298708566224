import {IMAGES} from "../../constants/IMAGES";

const AboutAside = () => {
    return <div className="p-l p-r about">
        <p className="p-t align-center flex-row flex-no-wrap flex-middle p-l-lg p-r-lg">
            <span className="size-1 flex-column">"</span>
            <span className="flex-column">AUTOFAST es una empresa especializada en el alquiler de vehículos con el respaldo de Carsoon Rent a Car, que cuenta con más de 10 años de experiencia dentro del mercado ecuatoriano.</span>
        </p>
        <div className="align-center p-t p-b">
            <img src={IMAGES.ABOUT} className="about-image" alt=""/>
        </div>
        <div className="blocks-content">
            <div className="block-item">
                <h4 className="text-primary primary-font">
                    Misión
                </h4>
                <p>
                    Brindar un servicio integral y con un alto estándar de calidad. AUTOFAST ofrece renta de autos con vehículos modernos y seguros, con precios acordes al mercado, para que cada uno de nuestros clientes tenga un alto índice de satisfacción.
                </p>
            </div>
            <div className="block-item">
                <h4 className="text-primary primary-font">
                    Visión
                </h4>
                <p>
                    Ser líderes dentro del mercado ecuatoriano a través de nuestro servicio, calidad e innovación. Buscamos la satisfacción de nuestros clientes con el apoyo de nuestro personal altamente calificado.
                </p>
            </div>
        </div>
    </div>
}

export default AboutAside