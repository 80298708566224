import * as React from 'react'
import {IMAGES} from "../../constants/IMAGES";
import {AsideContext} from "../../hooks/asideContext";
import AboutAside from "../aside/AboutAside";
import WorkAside from "../aside/WorkAside";

export const AsideLayoutComponent = () => {
    const {handleAsideData, asideData} = React.useContext(AsideContext);
    const validateScape = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            close()
        }
    }
    const close = () => {
        handleAsideData({
            aside: '',
            title: '',
            showAside: false,
            bottomTitle: '',
            subtitle: ''
        })
    }
    const getContent = () => {
        switch (asideData.aside) {
            case 'ABOUT_US':
                return <AboutAside />
            case 'WORK':
                return <WorkAside />
            default:
                return null
        }
    }
    React.useEffect(() => {
        window.addEventListener('keyup', validateScape)
        return () => {
            window.removeEventListener('keyup', validateScape)
        }
    }, [])
    if (!asideData.showAside) {
        return null
    }
    return (
        <div className={`aside-layout-component width-100 flex-column height-100`}>
            <div className="layout-content p-l p-r">
                <div className="title-block p-t">
                    <h2 className="p-l p-r line-height-small">
                        {asideData.title}
                        <span className="text-primary"> {asideData.subtitle}</span>
                    </h2>
                    <h3 className="p-b-s regular p-l p-r primary-font">{asideData.bottomTitle}</h3>
                    <a className="close-button cursor-pointer" onClick={close}>
                        <img src={IMAGES.CLOSE} alt="cerrar" className="icon-16x"/>
                    </a>
                </div>
                <div className="aside-content-area flex-row flex-1">
                    {getContent()}
                </div>
            </div>
        </div>
    )
}