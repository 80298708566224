import {IMAGES} from "../../constants/IMAGES";
import {InputComponent} from "../global/InputComponent";
import * as React from "react";
import {ButtonComponent} from "../global/ButtonComponent";

const WorkAside = () => {
    const [formData, handleFormData] = React.useState({
        name: '',
        lastname: '',
        phone: '',
        email: '',
        message: ''
    })
    const changeData = (value: string | number, id: string) => {
        handleFormData({
            ...formData,
            [id]: value
        })
    }
    return <div className="about width-100">
        <div className="p-t align-center">
            <h4 className="text-primary primary-font">
                ¿Necesitas ingresos extra? ¿Tienes un vehículo?
            </h4>
            <h3 className="regular line-height-small">
                ¡NO PIERDAS ESTA OPORTUNIDAD!
            </h3>
        </div>
        <p className="p-t align-center flex-row flex-no-wrap flex-middle p-l-lg p-r-lg m-auto flex-center">
            <span className="size-1 flex-column">"</span>
            <span className="flex-column">
                <span>Requerimos vehículos asegurados para completar nuestra flota de autos.</span>
                <span>Por favor llena el formulario adjunto o comunícate con nosotros si estás interesado.</span>
            </span>
        </p>
        <div className="blocks-content width-100 p-t">
            <div className="block-item">
                <div className="p-b p-l p-r">
                    <InputComponent
                        label="Nombre"
                        placeholder="Ejm: Juan"
                        valueId="name"
                        value={formData.name}
                        onChangeValue={changeData}
                        icon={<img src={IMAGES.NAME_SELECT} alt="nombre" width={18} height={18}/>}
                    />
                </div>
            </div>
            <div className="block-item">
                <div className="p-b p-l p-r">
                    <InputComponent
                        label="Apellido"
                        placeholder="Ejm: Barros"
                        valueId="lastname"
                        value={formData.lastname}
                        onChangeValue={changeData}
                        icon={<img src={IMAGES.NAME_SELECT} alt="Apellido" width={18} height={18}/>}
                    />
                </div>
            </div>
        </div>
        <div className="blocks-content width-100">
            <div className="block-item">
                <div className="p-b p-l p-r">
                    <InputComponent
                        label="Email"
                        placeholder="Ejm: juan@email.com"
                        valueId="email"
                        value={formData.email}
                        onChangeValue={changeData}
                        icon={<img src={IMAGES.MAIL_FORM} alt="email" width={18} height={18}/>}
                    />
                </div>
            </div>
            <div className="block-item">
                <div className="p-b p-l p-r">
                    <InputComponent
                        label="Teléfono"
                        placeholder="Ejm: 0911111222"
                        valueId="phone"
                        value={formData.phone}
                        onChangeValue={changeData}
                        icon={<img src={IMAGES.CALL} alt="Teléfono" width={18} height={18}/>}
                    />
                </div>
            </div>
        </div>
        <div className="blocks-content width-100">
            <div className="block-item">
                <div className="p-b p-l p-r">
                    <InputComponent
                        textArea={true}
                        label="Mensaje"
                        placeholder="Ejm: detalle de su vehículo"
                        valueId="message"
                        value={formData.message}
                        onChangeValue={changeData}
                    />
                </div>
            </div>
        </div>
        <div className="width-100 align-right p-r">
            <ButtonComponent buttonText="Enviar solicitud" buttonType="primary" />
        </div>
    </div>
}

export default WorkAside