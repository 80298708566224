import * as React from "react";
import {ModalContext} from "../../hooks/modalContext";
import {MODALS} from "../layout/ModalContainer";
import {IMAGES} from "../../constants/IMAGES";
import {InputComponent} from "../global/InputComponent";
import {SelectComponent} from "../global/SelectComponent";
import {VEHICLES} from "../../data/vehicles";
import RadioButtonComponent from "../global/RadioButtonComponent";
import {ButtonComponent} from "../global/ButtonComponent";
import DatePickerComponent from "../global/DatePickerComponent";

const ContactModal = () => {
    const [formData, handleFormData] = React.useState({
        user: '',
        phone: '',
        email: '',
        message: ''
    })
    const changeData = (value: string | number, id: string) => {
        handleFormData({
            ...formData,
            [id]: value
        })
    }
    return <div className="width-100 contact-modal form-modal">
        <div className="left-area">
            <div>
                <img src={IMAGES.EMAIL_LINE} alt="contacto" className="icon-64x"/>
            </div>
            <div className="description-form">
                En <strong className="text-primary">AUTOFAST</strong> nos interesa conocer tu opinión, inquietudes o sugerencias; por favor completa el siguiente formulario y uno de nuestros asesores te contactará de inmediato
            </div>
        </div>
        <div className="contact-form">
            <div className="form-area width-100 p-t">
                <div className="width-100 p-b">
                    <InputComponent
                        label="Ingresa tus nombres"
                        placeholder="Ejm: Adriana Suarez"
                        valueId="user"
                        value={formData.user}
                        onChangeValue={changeData}
                        icon={<img src={IMAGES.NAME_SELECT} alt="Nombres" width={24} height={24}/>}
                    />
                </div>
                <div className="width-100 p-b flex-row">
                    <div className="flex-column flex-1 p-r-xs">
                        <InputComponent
                            label="Email"
                            placeholder="Ejm: asuarez@email.com"
                            valueId="email"
                            value={formData.email}
                            onChangeValue={changeData}
                            icon={<img src={IMAGES.MAIL_FORM} alt="Email" width={18} height={18}/>}
                        />
                    </div>
                    <div className="flex-column flex-1 p-l-xs">
                        <InputComponent
                            label="Teléfono"
                            placeholder="Ejm: 0999123123"
                            valueId="email"
                            value={formData.phone}
                            onChangeValue={changeData}
                            icon={<img src={IMAGES.CALL} alt="Teléfono" width={18} height={18}/>}
                        />
                    </div>
                </div>
                <div className="width-100 p-b">
                    <InputComponent
                        textArea={true}
                        label="Ingresa tu mensaje"
                        placeholder="Ejm: Hola, los saluda..."
                        valueId="message"
                        value={formData.message}
                        onChangeValue={changeData}
                    />
                </div>
                <div className="width-100 align-right">
                    <ButtonComponent buttonText="Contactar" buttonType="primary" />
                </div>
            </div>
        </div>
    </div>
}

export default ContactModal