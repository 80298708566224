import BRAND_PNG from '../assets/images/autofast.png'
import BRAND_SVG from '../assets/images/autofast.svg'
import HAND_CLICK from '../assets/images/hand-click.svg'
import CLOSE from '../assets/images/close.svg'
import FACEBOOK from '../assets/images/facebook.png'
import INSTAGRAM from '../assets/images/instagram.png'
import WHATSAPP from '../assets/images/whatsapp.png'
import WHATSAPP_SVG from '../assets/images/whatsapp.svg'
import PROMO from '../assets/images/promo.svg'
import PHONE from '../assets/images/phone.svg'
import FILTER from '../assets/images/filter.svg'
import MINICOOPER from '../assets/images/alquiler-minicooper.png'
import DATE_FROM_WHITE from '../assets/images/date-from-white.svg'
import DATE_TO_WHITE from '../assets/images/date-to-white.svg'
import DATE_FROM_GRAY from '../assets/images/date-from-gray.svg'
import DATE_TO_GRAY from '../assets/images/date-to-gray.svg'
import ARROW_RIGHT from '../assets/images/arrow-right.svg'
import VEHICLES_ALL from '../assets/images/all-vehicles.svg'
import VEHICLES_SEDAN from '../assets/images/sedan.svg'
import VEHICLES_HIGH from '../assets/images/high-end.svg'
import VEHICLES_SUV from '../assets/images/suv.svg'
import VEHICLES_TRUNK from '../assets/images/trink.svg'
import VEHICLES_VAN from '../assets/images/van.svg'
import VEHICLES_ALL_P from '../assets/images/all_vehicles_p.svg'
import VEHICLES_SEDAN_P from '../assets/images/sedan_p.svg'
import VEHICLES_HIGH_P from '../assets/images/high_end_p.svg'
import VEHICLES_SUV_P from '../assets/images/suv_p.svg'
import VEHICLES_TRUNK_P from '../assets/images/trunk_p.svg'
import VEHICLES_VAN_P from '../assets/images/van_p.svg'
import BG from '../assets/images/bg_alquiler.jpg'
import BG_RESPONSIVE from '../assets/images/bg_alquiler_responsive.jpg'
import BG_WEB from '../assets/images/bg_alquiler_web.jpg'
import CHAIRS from '../assets/images/chair.svg'
import DOOR from '../assets/images/door.svg'
import BLUETOOTH from '../assets/images/bluetooth.svg'
import TRACTION from '../assets/images/traction.svg'
import AC from '../assets/images/ac.svg'
import WHATSAPP_LINE from '../assets/images/whatsapp-line.svg'
import EMAIL_LINE from '../assets/images/email-outline.svg'
import CALENDAR from '../assets/images/calendar.svg'
import NO_VEHICLE from '../assets/images/no_vehicle.png'
import CAR_SELECT from '../assets/images/car-select.svg'
import NAME_SELECT from '../assets/images/name.svg'
import CALL from '../assets/images/call.svg'
import MAIL_FORM from '../assets/images/mail-form.svg'
import ABOUT from '../assets/images/nosotros.jpeg'

export const IMAGES = {
    BRAND_PNG,
    BRAND_SVG,
    HAND_CLICK,
    CLOSE,
    WHATSAPP,
    INSTAGRAM,
    FACEBOOK,
    WHATSAPP_SVG,
    PROMO,
    PHONE,
    FILTER,
    MINICOOPER,
    DATE_FROM_WHITE,
    DATE_TO_WHITE,
    ARROW_RIGHT,
    VEHICLES_ALL,
    VEHICLES_SEDAN,
    VEHICLES_HIGH,
    VEHICLES_SUV,
    VEHICLES_TRUNK,
    VEHICLES_VAN,
    VEHICLES_ALL_P,
    VEHICLES_SEDAN_P,
    VEHICLES_HIGH_P,
    VEHICLES_SUV_P,
    VEHICLES_TRUNK_P,
    VEHICLES_VAN_P,
    BG,
    BG_RESPONSIVE,
    BG_WEB,
    CHAIRS,
    BLUETOOTH,
    TRACTION,
    AC,
    DOOR,
    WHATSAPP_LINE,
    EMAIL_LINE,
    CALENDAR,
    NO_VEHICLE,
    CAR_SELECT,
    NAME_SELECT,
    CALL,
    MAIL_FORM,
    DATE_FROM_GRAY,
    DATE_TO_GRAY,
    ABOUT
}