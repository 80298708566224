import * as React from "react";
import {ModalContext} from "../../hooks/modalContext";
import {IMAGES} from "../../constants/IMAGES";
import ScheduleModal from "../modals/ScheduleModal";
import ContactModal from "../modals/ContactModal";
import RequirementsModal from "../modals/RequirementsModal";

export const MODALS = {
    DATE_MODAL: 'DATE_MODAL',
    ONE_CLICK_MODAL: 'ONE_CLICK_MODAL',
    WHATSAPP_MODAL: 'WHATSAPP_MODAL',
    PROMO_MODAL: 'PROMO_MODAL',
    VEHICLE_MODAL: 'VEHICLE_MODAL',
    CONTACT_MODAL: 'CONTACT_MODAL',
    REQUIREMENTS_MODAL: 'REQUIREMENTS_MODAL',
}

const ModalContainer = () => {
    const {handleModalData, modalData} = React.useContext(ModalContext);
    const validateScape = (e: KeyboardEvent) => {
        if (e.key === 'Escape') {
            close()
        }
    }
    const close = () => {
        handleModalData({
            modal: '',
            title: '',
            extraData: {},
            showModal: false,
            subtitle: ''
        })
    }
    React.useEffect(() => {
        window.addEventListener('keyup', validateScape)
        return () => {
            window.removeEventListener('keyup', validateScape)
        }
    }, [])

    const getActiveComponent = () => {
        switch (modalData.modal as keyof typeof MODALS) {
            case 'DATE_MODAL':
            case 'WHATSAPP_MODAL':
            case 'ONE_CLICK_MODAL':
            case 'VEHICLE_MODAL':
            case 'PROMO_MODAL':
                return ScheduleModal
            case 'CONTACT_MODAL':
                return ContactModal
            case 'REQUIREMENTS_MODAL':
                return RequirementsModal
            default:
                return null
        }
    }

    const ActiveComponent = getActiveComponent()
    if (!modalData.showModal) {
        return null
    }
    return (
        <div
            className={`modal-view ${modalData.showModal && 'modal-active'}`}
        >
            <div className="close-area" onClick={close} />
            <div
                className={`modal-container`}
            >
                <div className="modal-header">
                    <a className="close-button" onClick={() => close()}>
                        <img src={IMAGES.CLOSE} width={20} height={20} alt="Cerrar"/>
                    </a>
                    <h4 className="line-height-small p-r">{modalData.title}
                        <span className="text-primary">
                            {` ${modalData.subtitle}`}
                        </span>
                    </h4>
                </div>
                <div className="modal-body">
                    {ActiveComponent && <ActiveComponent />}
                </div>
            </div>
        </div>
    )
}

export default ModalContainer